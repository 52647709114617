var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "badge",
    class: _vm.color
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.icon,
      "alt": "Icon"
    }
  }), _c('p', {
    staticClass: "text"
  }, [_vm._t("text")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };